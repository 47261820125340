<template>
  <div>
    <van-cell-group style="margin-top:1px">
      <van-cell style="padding:15px 10px 16px 10px">
        <template #title>
          <span class="custom-title hidden-text">
            <svg-icon icon-class="layers" size="18" style="margin-right:8px" /> {{row.schshiName}}
          </span>
        </template>
        <template #right-icon>
          <div class="status-div">
            <span class="status-div-text">{{row.status === '1' ?"已开启":"未开启"}}</span>
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-field v-model="row.schshiNo" readonly label="项目代码" />
    <van-field v-model="typeName" readonly label="项目类型" />
    <van-field v-model="row.startTime" readonly label="开始时间" />
    <van-field v-model="row.endTime" readonly label="结束时间" />
    <van-field v-model="row.remarks" readonly rows="3" class="textarea-style" label="备注" type="textarea" />
  </div>
</template>

<script>
import { isDuringDate, getTermYear, getTerm } from "@/utils/index";
import { getList } from "@/api/modules/award/stujxj";
import { webService } from "@/config/settings";
import { mapGetters } from "vuex";
import { getAwardById } from '@/api/modules/jz/project'


export default {
  name: "ProjectDetail",
  components: {},
  dicts: ["xj_xqgl"],
  data() {
    return {
      row: {},
      typeName: "",
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  created() {
    let id = this.$route.query.code
    this.$nextTick((e) => {
      getAwardById(id).then((data) => {
        if (data) {
          this.row = data
          let type = data.typeNo === "jxj" ? "奖学金" : "助学金";
          this.typeName = type;
        }
      })
    });
  },
  methods: {
    isDuringDate,
  },
};
</script>

<style  lang='scss' scoped>
.textarea-style {
  height: 100% !important;
}
.status-div {
  width: 60px;
  height: 22px;
  background-color: rgba(35, 171, 114, 0.16);
  border-radius: 30px;
  text-align: center;
  &-text {
    color: rgba(35, 171, 114, 1);
  }
}
</style>
